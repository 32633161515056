import "bootstrap/dist/css/bootstrap.min.css"
import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./assets/scss/style.scss"
import "./assets/scss/theme.scss"
import HorizontalLayout from "./components/HorizontalLayout/"
// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
// Import all middleware
import AuthMiddleware from "./routes/Middleware/AuthMiddleware"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes/Routes"
// Activating fake backend
// fakeBackend()
toast.configure()

const App = props => {
  function getLayout() {
    let layoutCls = VerticalLayout
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  const Layout = getLayout()
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Route
              path={route.path}
              // layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AuthMiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              roles={route.roles}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
