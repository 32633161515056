import React from "react"
import { Col, Container, Row } from "reactstrap"

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={6}>{new Date().getFullYear()} © Xponent.</Col>
            <Col md={6}>
              <div className="text-sm-end d-none d-sm-block">
                <div>
                  <ul className="navbar-nav d-flex flex-row justify-content-end">
                    <li className="nav-item">Contact Us</li>
                    <li className="nav-item mx-2">Privacy Policy</li>
                    <li className="nav-item">Terms & Condition</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
